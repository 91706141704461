<template>
    <!-- Composant affichant un Article en mode résumé -->
    <div class="deck-group">
      <h3>{{ title }}</h3>
      <b-card-group deck>
        <b-card :title="article.titre"
          v-for="article in articles"
          :key="article.id"
        >
          <b-link :to="{ name: 'Article', params: { id: article.codeUrl } }" class="cat-link">
            <b-card-text v-html="article.resume" >
            </b-card-text>
          </b-link>
          <template #footer>
            <small class="text-muted">{{ article.nbVues }} vues</small>
          </template>
        </b-card>
      </b-card-group>
    </div>
</template>

<script>
import router from '../router/index'

export default {
  name: 'ArticleSummaryGroup',
  props: {
    articles: {},
    title: String
  },
  methods: {
    readArticle: function (article) {
      router.push({
        name: 'Article',
        params: {
          id: article.codeUrl ? article.codeUrl : article.id
        }
      })
    }
  }
}
</script>

<style scoped>
.deck-group {
  padding-left: 10%;
  font-size: small;
  width: 90%;
}
</style>
