<template>
  <b-col :class="[color]">
    <label>{{ getTitle }}</label>
    <b-form-input v-model="score" @update="updateScore" type="number" :min="0"></b-form-input>
  </b-col>
</template>

<script>
export default {
  name: 'Zone',
  /* props: propriétés qui peuvent être envoyées par le parent */
  props: {
    color: String,
    type: String
  },
  data: function () {
    return {
      score: null
    }
  },
  computed: {
    getTitle: function () {
      let title = ''
      switch (this.type) {
        case 'commercial':
          title += 'Zone Commerciale'
          break
        case 'cornfield':
          title += 'Champs de blé'
          break
        case 'industrial':
          title += 'Zone Industrielle'
          break
        case 'livestock':
          title += 'Bétail'
          break
        case 'orchard':
          title += 'Verger'
          break
        case 'park':
          title += 'Zone de Parcs'
          break
        case 'residential':
          title += 'Zone Résidentielle'
          break
        case 'vineyard':
          title += 'Vignoble'
          break
        default:
          break
      }
      return title
    }
  },
  methods: {
    updateScore: function () {
      this.$emit('zone-score-event', this.type, this.score)
    }
  }
}
</script>

<style scoped>

.blue {
  background-color: deepskyblue;
  padding: 5px;
}
.brown {
  background-color: burlywood;
  padding: 5px;
}
.green {
  background-color: forestgreen;
  padding: 5px;
}
.grey {
  background-color: darkgrey;
  padding: 5px;
}
.orange {
  background-color: chocolate;
  padding: 5px;
}
.purple {
  background-color: rgb(167, 42, 167);
  padding: 5px;
  color: lightgray;
}
.red {
  background-color: rgb(224, 59, 59);
  padding: 5px;
  color: lightgray;
}
.yellow {background-color: rgb(221, 221, 35);
  padding: 5px;
}
</style>
