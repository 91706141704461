<template>
  <div class="div-jeu">
    <b-container class="bv-example-row">
      <b-row v-if="jeu">
        <span>{{ getTitre }}, {{ getAuteurs }}</span>
        <!--<li>{{ getIllustrateurs }}, {{ getEditeurs }}</li>-->
      </b-row>
    </b-container>
  </div>
</template>

<script>
import constants from '../globals'

export default {
  name: 'JeuLight',
  props: {
    jeu: {}
  },
  computed: {
    getAnnee: function () {
      return this.jeu.annee ? '(' + this.jeu.annee + ')' : ''
    },
    getAuteurs: function () {
      const authors = []
      this.jeu.personnalites.auteurs.forEach(perso => {
        // Initiale du prénom plus nom, si on a un prénom, sinon juste le prenom
        let prenom = ''
        if (perso.prenom) {
          prenom = perso.prenom.charAt(0) + '.'
        }
        authors.push(prenom + perso.nom)
      })
      return 'créé par ' + authors.join(', ')
    },
    getEditeurs: function () {
      const editors = []
      this.jeu.editeurs.forEach(editor => {
        editors.push(editor.libelle)
      })
      return 'édité par ' + editors.join(', ')
    },
    getIllustrateurs: function () {
      const illus = []
      this.jeu.personnalites.illustrateurs.forEach(perso => {
        // Initiale du prénom plus nom, si on a un prénom, sinon juste le prenom
        let prenom = ''
        if (perso.prenom) {
          prenom = perso.prenom.charAt(0) + '.'
        }
        illus.push(prenom + perso.nom)
      })
      return 'Illustré par ' + illus.join(', ')
    },
    getTitre: function () {
      return this.jeu.nom
    }
  }
}
</script>
<style scoped>
li {
  list-style: none;
}
.div-jeu {
  text-align: left;
}
.header-card-image {
  display: block;
  max-height: 9em;
  margin-left: auto;
  max-width: 100%;
}
.div-article{
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
